import BeatLoader from 'components/animations/Loaders/BeatLoader';
import CoalesceContainer from 'components/containers/CoalesceContainer';
import InfiniteScrollContainer from 'components/containers/InfiniteScrollContainer';
import FeedPropertyCardLoader from 'components/properties/PropertyCard/FeedPropertyCard/components/FeedPropertyCardLoader';
import FeedEmptyState from 'pages/feed/components/FeedEmptyState';
import FeedItem from 'pages/feed/components/FeedItem';
import classes from 'pages/feed/components/PropertiesContainer/components/PropertiesGrid/index.module.scss';
import { FeedPropertyType } from 'pages/feed/components/PropertiesContainer/hooks/useFeedItemsFetcher';
import React from 'react';

export type PropertiesFetcher<T> = (props: T) => {
  loading: boolean;
  data: FeedPropertyType[];
  fetchNextPage: () => void;
  hasMore: boolean;
  initialized: boolean;
};

interface Props<T> {
  preloadedProperties: FeedPropertyType[];
  onScroll?: (e: MouseEvent) => void;
  fetcher: PropertiesFetcher<T>;
  usePreloadedPropertiesCondition?: boolean;
  startedWorkingCondition?: boolean;
  fetcherProps: T;
}

const PropertiesGrid = <T,>({
  preloadedProperties,
  onScroll,
  fetcher,
  usePreloadedPropertiesCondition = true,
  startedWorkingCondition = true,
  fetcherProps,
}: Props<T>) => {
  const {
    loading: fetcherLoading,
    data,
    fetchNextPage,
    hasMore,
    initialized: fetcherInitialized,
  } = fetcher(fetcherProps);

  // Logic for what to show
  const startedWorking = fetcherInitialized && startedWorkingCondition;
  const dataEmpty = data.length === 0;
  const usePreloadedProperties =
    startedWorking &&
    fetcherLoading &&
    dataEmpty &&
    usePreloadedPropertiesCondition;
  const loading = fetcherLoading;
  const firstTimeLoading = !startedWorking || (dataEmpty && loading);
  const finalData = usePreloadedProperties ? preloadedProperties : data;

  console.log('=================================');

  return (
    <InfiniteScrollContainer
      outerDivClassName={classes.outerDiv}
      next={fetchNextPage}
      hasMore={hasMore && !usePreloadedProperties}
      dataLength={data.length}
      loader={loading && <BeatLoader className={classes.loadMore} />}
      onScroll={onScroll}
    >
      <div className={classes.grid}>
        <CoalesceContainer>
          {firstTimeLoading &&
            [...Array(8)].map((e, i) => <FeedPropertyCardLoader key={i} />)}
          {!finalData?.length && <FeedEmptyState />}
          {finalData?.map((item, index) => (
            <FeedItem
              item={item}
              key={index.toString()}
            />
          ))}
        </CoalesceContainer>
      </div>
    </InfiniteScrollContainer>
  );
};

export default PropertiesGrid;
