import { QueryResult } from '@apollo/client';
import useAuth from 'context/Auth';
import { ELIGIBLE_DISCOUNT_QUERY } from 'graphql/account/queries/eligibleDiscountQuery';
import { useLandaApiLazyQuery } from 'hooks/api/graphql/landa_api';
import { useEffect, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

const init: {
  eligibleDiscount?: boolean;
  discountPromotionEndDate?: string;
  loading: boolean;
  request?: () => Promise<QueryResult> | undefined;
} = {
  discountPromotionEndDate: '',
  eligibleDiscount: false,
  loading: false,
  request: undefined,
};

const useEligibleDiscount = () => {
  const { isLoggedIn } = useAuth();
  const [discountPromotionEndDate, setDiscountPromotionEndDate] =
    useState<string>();
  const [eligibleDiscount, setEligibleDiscount] = useState<boolean>();
  const [request, { loading, data }] = useLandaApiLazyQuery(
    ELIGIBLE_DISCOUNT_QUERY,
    {
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (!isLoggedIn) return;
    request();
  }, [isLoggedIn]);

  useEffect(() => {
    if (data) {
      setDiscountPromotionEndDate(data?.account?.discountPromotionEndDate);
      const curDate = new Date();
      setEligibleDiscount(
        curDate < new Date(data?.account?.discountPromotionEndDate)
      );
    }
  }, [data]);

  return {
    discountPromotionEndDate,
    eligibleDiscount,
    loading,
    request,
  };
};

export default singletonHook(init, useEligibleDiscount);
