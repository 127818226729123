import React, { HTMLAttributes, useMemo, useState } from 'react';
import Typography from '../../../../../components/generics/texts/Typography';
import classes from './index.module.scss';
import classNames from 'classnames';
import useEligibleDiscount from '../../../../../hooks/requests/account/useEligibleDiscount';
import useAppSettings from '../../../../../hooks/requests/useAppSettings/useAppSettings';
import Link from 'next/link';

const BlackFridayDiscountBanner = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  const { discountPromotionEndDate } = useEligibleDiscount();
  const { appSettings } = useAppSettings();

  const toDate = useMemo(() => {
    const eligibleEndDate =
      discountPromotionEndDate && new Date(discountPromotionEndDate);
    const globalEndDate = new Date(appSettings.landaPromotionEndDate);
    if (eligibleEndDate && eligibleEndDate > globalEndDate) {
      return eligibleEndDate;
    }
    return globalEndDate;
  }, [discountPromotionEndDate, appSettings]);

  const [timesUp, setTimesUp] = useState(toDate < new Date());

  if (timesUp) return null;

  return (
    <div
      className={classNames(classes.container, className)}
      {...props}
    >
      <Typography className={classes.title}>
        BLACK FRIDAY <Typography className={classes.lime}> Offer</Typography>
      </Typography>
      <Typography>
        <Link
          href={
            'https://made-with-love.landa.app/sec/offering/Offer+Terms+and+Conditions+-+Black+Friday.pdf'
          }
          target="_blank"
          className={classes.link}
        >
          Read More
        </Link>
      </Typography>
    </div>
  );
};

export default BlackFridayDiscountBanner;
